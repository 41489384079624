/*
 * Skin: Custom for Wellness Concepts
 * ----------------------------------
 */
@import "../../bootstrap/mixins.less";
@import "../../bootstrap/variables.less";
@import "../variables.less";
@import "../mixins.less";

.skin-custom-wc {
  //Navbar
  .main-header {
    color: #3f3f3f;
    .navbar {
      background-color: white;
    }
    //Logo
    .logo {
      background-color: white;
    }
    .sidebar-toggle {
      color: #3f3f3f;
      &:hover {
        color: white;
        background-color: #3f3f3f;
      }
    }
    li.user-menu > a {
      color: #3f3f3f;
    }
    li.user-header {
      background-color: #3f3f3f;
    }
  }
  .mainheader-title {
    color: #3f3f3f;
    @media (min-width: @grid-float-breakpoint) {
      padding-right: 230px;
    }
  }

  //Content Header
  .content-header {
    background: transparent;
  }

  //Create the sidebar skin
  .skin-light-sidebar(#3f3f3f);
  .main-footer {
    border-top-color: #3f3f3f;
  }

  .sidebar-menu > li {
    &.header {
      color: lighten(@sidebar-light-color, 75%);
      background-color: darken(#3f3f3f, 10%);
    }
  }
}
